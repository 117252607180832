@import "~bootstrap/scss/bootstrap";
//styles normalizer
@import '~modern-normalize/modern-normalize.css';
//fonts
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;400;500&display=swap");
//keyframes
@import "keyframes";
//transitions
@import "transitions";
//theme
@import "theme";
@import "atoms";
@import "actionsButtons";

@font-face {
  font-family: Montserrat;
  src: url(../fonts/Montserrat-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

html, body {
  font-size: 16px;
  margin: 0;
  font-family: "JetBrainsMono-Regular", serif;
  overflow-x: clip;
}

body {
  height: 100vh;
  width: 100%;
}

@media (max-width: 600px) {
  html {
    font-size: 12px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

button {
  background-color: transparent;
  color: inherit;
  border-width: 0;
  padding: 0;
  cursor: pointer;
}

figure {
  margin: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin: 0;
}

ul, ol, dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

p {
  margin: 0;
}

cite {
  font-style: normal;
}

fieldset {
  border-width: 0;
  padding: 0;
  margin: 0;
}

.overflow-hidden {
  overflow: hidden;
}

#tsparticles {
  position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #2d1950;
}

::-webkit-scrollbar-thumb {
  background: rgba(178, 121, 216, 0.959);
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}

.lazy-load-image-background.blur {
  filter: blur(15px);
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  filter: blur(0);
  transition: filter .3s;
}

.lazy-load-image-background.blur > img {
  opacity: 0;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  opacity: 1;
  transition: opacity .3s;
}

.switchVisible {
  .MuiSwitch-track {
    background-color: var(--ani-very-light-pink) !important;
  }

  .MuiSwitch-thumb {
    background-color: var(--ani-light-pink) !important;
  }
}

.switchInvisible {
  .MuiSwitch-track {
    background-color: var(--ani-purpel) !important;
    border: 1px solid var(--ani-light-pink);
  }

  .MuiSwitch-thumb {
    background-color: var(--ccc) !important;
    border: 1px solid var(--ani-light-pink);
  }
}