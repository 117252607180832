.light {
  --txt: #000;
  --txt-secondary: #cd5ff8;
  --txt-sec-darker: #cd5ff8;
  --bg-layout-home: linear-gradient(307deg, transparent 0%, transparent 57%, #ffffff 57%, #f8f8f8 100%), linear-gradient(to bottom, #ffffff 0%, #f8f8f8 100%);
  --bg-home-section-main: none;
  --bg-layout-base: linear-gradient(307deg, transparent 0%, transparent 57%, #ffffff 57%, #f8f8f8 100%), linear-gradient(to bottom, #ffffff 0%, #f8f8f8 100%);
  --bg-primary: #fafafa;
  --bg: #623686;
  --bg-active: rgba(109,32,197,.84);
  --bg-header: transparent;
  --bg-footer: transparent;
  --backdrop: rgba(0, 0, 0, 0.75);
  --nav-active: #c95bf5;
  --border-primary: #8a49a8;
  --logo-primary: #ce5ff8;
  --social-icon: #700c86;
  --social-shadow: #801f95;
  --preloader: #fafafa;
  --bg-modal: #fafafa;
  --modal-border: none;
  --modal-close: #623686;
  --modal-close-icon: #fff;
  --drawer-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
  --btn-primary-txt: #fff;
  --tags-txt: #fff;
  --toggle-theme: #000;
}

.dark {
  --txt: #fff;
  --txt-secondary: #cd5ff8;
  --txt-sec-darcker: #cd5ff8;
  --bg-layout-home: linear-gradient(to left, #1A002C, #1A002C);
  --bg-home-section-main: linear-gradient(to bottom left, rgba(17, 16, 16, 0.278), rgba(12, 10, 22, 0.863)), url(../assets/animeta-background.png);
  --bg-home-section-footer: linear-gradient(to bottom left, rgba(26, 0, 44, 0.278), rgba(26, 0, 44, 0.863)), url(../assets/animeta-background-space-world.png);
  --bg-layout-base: #1A002C;
  --bg-primary: #181a27;
  --bg: #623686;
  --bg-active: rgba(109,32,197,.84);
  --bg-header: rgba(26,0,44,0.84);
  --bg-footer: #0a0416;
  --backdrop: rgba(0, 0, 0, 0.75);
  --nav-active: #c95bf5;
  --border-primary: #8a49a8;
  --logo-primary: #ce5ff8;
  --social-icon: #700c86;
  --social-shadow: #801f95;
  --preloader: #0c0513;
  --bg-modal: #100D19;
  --bg-modal-opc: linear-gradient( 45deg , #1a002d 0%, #9719b9 100%);
  --bg-modal-cool: linear-gradient(45deg , #1a002d 30%, #560092 100%);
  --modal-border: 2px solid rgba(201,83,255,0.7);
  --modal-close: #623686;
  --modal-close-icon: #fff;
  --drawer-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  --btn-primary-txt: #fff;
  --tags-txt: #fff;
  --toggle-theme: #fff;

  --aaa: #db97ed;
  --bbb: #f0cbff;
  --bbb-opc: rgba(240,203,255,0.1);
  --ccc: #1a002d;
  --ccc-opc: rgba(26,0,25,0.1);
  --ddd: #560092;
  --ddd-opc: rgba(86,0,146,0.4);
  --eee: #9719b9;
  --eee-opc: rgba(151,25,185,0.4);
  --fff: #e70078;
  --hhh: #b80fcd;

  --ani-pink: #E41378;
  --ani-purpel: #570094;
  --ani-light-pink: #DB97ED;
  --ani-light-pink-opc: rgba(219, 151, 237, 0.7);
  --ani-very-light-pink: #EFCCFE;
}