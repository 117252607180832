@import "flex";

.with-auto {
  width: auto;
}

.hide {
  display: none !important;
}

.font-size-2 {
  font-size: 2rem;
}

.col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row-center {
  @include flex-row-center;
}

.aniActionBtn {
  background-color: transparent;
  border-color: var(--aaa);
  box-shadow: 2px 2px var(--bbb), 4px 5px var(--ccc), 6px 6px var(--eee);
  border-radius: 5px;

  &:hover {
    color: var(--ccc);
    border-color: var(--bbb);
    background-color: var(--bbb);
    transition: all 0.5s ease 0s;
  }
}

$margin_sizes: 40;
@mixin margin-padding-x-list {
  @for $i from 1 through $margin_sizes {
    $c: $i*0.125;
    $c1: str-replace(#{$c}, '.', '_');

    .mtop-#{$c1} {
      margin-top: #{$c}rem;
    }
    .mbottom-#{$c1} {
      margin-bottom: #{$c}rem;
    }
    .mright-#{$c1} {
      margin-right: #{$c}rem;
    }
    .mleft-#{$c1} {
      margin-left: #{$c}rem;
    }
    .margin-#{$c1} {
      margin: #{$c}rem;
    }
    .ptop-#{$c1} {
      padding-top: #{$c}rem;
    }
    .pbottom-#{$c1} {
      padding-bottom: #{$c}rem;
    }
    .pright-#{$c1} {
      padding-right: #{$c}rem;
    }
    .pleft-#{$c1} {
      padding-left: #{$c}rem;
    }
    .padding-#{$c1} {
      padding: #{$c}rem;
    }
  }
}

@include margin-padding-x-list;

$width_sizes: 105;
@mixin width-height-sizing-x-list {
  @for $i from 1 through $width_sizes {
    $c: $i*1;
    .w-#{$c} {
      width: $c * 1% !important;
    }

    .h-#{$c} {
      height: $c * 1% !important;
    }
  }
}

@include width-height-sizing-x-list;

.vjs-control-bar {
  border-radius: 16px;
}