@mixin row {
  display: flex;
  flex-direction: row;
}

@mixin col {
  display: flex;
  flex-direction: column;
}


@mixin flex-row-center {
  @include row;
  align-items: center;
  justify-content: center;
}