@mixin actionButton {
  background-color: transparent;
  border: 1px solid var(--aaa);
  box-shadow: 2px 2px var(--bbb), 4px 5px var(--ccc), 6px 6px var(--eee);
  border-radius: 5px;
  cursor: pointer;
  text-align: center;

  &:hover {
    color: var(--ccc);
    border-color: var(--bbb);
    background-color: var(--bbb);
    transition: all 0.5s ease 0s;
    backdrop-filter: none;
  }

  &.blur-background {
      backdrop-filter: blur(60px) saturate(100%);
  }

  &.pink-blur-brightness-background {
    background-color: var(--ani-light-pink-opc);
    backdrop-filter: blur(100px) opacity(50%) brightness(150%);
  }
}

.action-button-big {
  @include actionButton;
  font-size: 1.25rem;
  padding: 1rem 3rem;
}

.action-button-small {
  @include actionButton;
  padding: 0.25rem 0.25rem;
  font-size: 0.675rem;
}

.action-button-medium {
  @include actionButton;
  padding: 0.35rem 0.65rem;
  font-size: 1rem;
}

.action-button {
  @include actionButton;
  padding: 0.5rem 1rem;
}
