#wave {
	height: 40px;
	width: 40px;
	fill: var(--ani-very-light-pink);
	cursor: pointer;
}

@for $i from 1 through 9 {
	.playWave {
		#Line_#{$i} {
			animation: pulse 1s infinite;
			animation-delay: $i * .15s;
		}
	}
}

@keyframes pulse {
	0% {
		transform: scaleY(1);
		transform-origin: 50% 50%;
	}

	50% {
		transform: scaleY(.3);
		transform-origin: 50% 50%;
	}

	100% {
		transform: scaleY(1);
		transform-origin: 50% 50%;
	}
}