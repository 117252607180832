.purple {
  color: var(--txt-secondary);
}

.mintAvatrackButtonsWrapper {
  //margin-right: 0.5rem;
}

.overlay {
  position: absolute;
  //height: 100%;
  width: 100%;
  //background-color: var(--ccc-opc);
  z-index: 1;
  display: flex;
  justify-content: flex-end;

  .overlyContent {
    height: 100%;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .overlayBtn {
      height: 1rem;
      margin-left: 0.5rem;
    }

    .download {
      margin-right: 0.375rem;
    }
  }
}

.card {
  position: relative;
  width: 30%;
  margin: 2.5rem 1rem;
  min-width: 320px;
}

.imgWrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.img {
  z-index: 1;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.cardWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  border-radius: .25rem;
  opacity: 0.9;
  transition: all 0.5s ease 0s;
  box-shadow: 0 0 5px 5px rgba(119, 53, 136, 0.459);
  color: var(--txt);
  height: 100%;

  &:hover {
    transform: scale(1.02);
    overflow: hidden;
    box-shadow: 0 0 5px 7px rgba(129, 72, 144, 0.561);
  }

  & > a {
    width: 100%;
  }
}

.cardBody {
  flex: 1 1 auto;
  padding: 1.25rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.visibilitySection {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  font-size: 1rem;
  margin-top: 0.5rem;

  .text {
    font-size: 0.75rem;
    margin-left: 0.25rem;
  }
}

.accessMusicalBtn {
  margin-bottom: 1rem;
}

.title {
  margin-bottom: .75rem;
  font-size: 1.25rem;
  text-align: center;
}

.description {
  text-align: justify;
  margin-top: 0;
  margin-bottom: 1rem;
}

.backgroundStory {
  text-align: justify;
  margin-top: 0;
  margin-bottom: 1rem;
  max-height: 12rem;
  overflow-y: auto;
}